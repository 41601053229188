import React, { useCallback } from 'react'
import Group from 'components/utils/Group'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Image from 'components/Common/Image'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import styled from 'styled-components'
import { useAppDispatch } from 'hooks/reduxHooks'
import { replaceSearchString } from 'actions/NavigationActions'
import * as Analytics from 'analytics/analytics'
import { rem } from 'polished'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import Clickable from 'components/Common/Clickable'

const DestinationImage = styled(Image)`
  width: 220px;
  height: 80px;
  border-radius: ${props => props.theme.borderRadius.S};
`

const StyledGroup = styled(Group)`
  padding: ${rem(4)};
`

const StyledClickable = styled(Clickable)`
  ${mediaHoverable} {
    &:hover {
      background-color: ${props => props.theme.palette.neutral.default.seven};
    }
  }
`

interface Props {
  specificPlaces: Array<App.SpecificPlace>
  destinationName: string
  className?: string
}

function HotelBroadSearchLocation({ specificPlaces, destinationName, className }: Props) {
  const dispatch = useAppDispatch()

  const onClick = useCallback((id: string, name: string) => {
    Analytics.trackClientEvent({
      subject: `${id}-${name}`,
      action: 'clicked',
      category: 'broad_search_location',
      type: 'interaction',
    })
    dispatch(replaceSearchString({ destinationId: id, destinationName: name }))
  }, [dispatch])

  return (<Group className={className} direction="vertical" gap={8}>
    <BodyTextBlock variant="large">
      Explore top destinations in {destinationName}
    </BodyTextBlock>
    <CardCarousel gap={12}>
      {specificPlaces.map((destination) => (
        <StyledClickable onClick={() => onClick(destination.placeId, destination.name)} key={destination.placeId}>
          <DestinationImage id={destination.image} alt={destination.name}/>
          <StyledGroup direction="vertical">
            <BodyText variant="medium" weight="bold">{destination.name}</BodyText>
            <Caption colour="neutral-three" variant="medium">{destination.caption}</Caption>
          </StyledGroup>
        </StyledClickable>
      ))}
    </CardCarousel>
  </Group>)
}

export default HotelBroadSearchLocation
