import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { arrayToObject } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { buildParamsKey } from 'lib/offer/availabilityUtils'
import { useEffect, useMemo } from 'react'
import useOffer from './Offers/useOffer'
import { fetchAvailableRatesForOffer } from 'actions/OfferActions'
import { isValidOccupancy } from 'lib/offer/occupancyUtils'

const EmptyAvailableRates: App.OfferAvailableRates = {
  fetching: false,
  error: undefined,
  key: '',
  rates: [],
}

function useOfferAvailableRates(
  offerId?: string,
  params: {
    checkIn?: string;
    checkOut?: string;
    rooms?: Array<App.Occupants>;
    flightOrigin?: string;
  } = EmptyObject,
): App.OfferAvailableRates {
  const dispatch = useAppDispatch()
  const [offer] = useOffer<App.Offer>(offerId)
  const offerAvailableRates = useAppSelector(state => state.offer.offerAvailableRates[offerId || '']) ?? EmptyObject
  const offerAvailableRatesKey = useMemo(() => {
    if (params.checkIn && params.checkOut && params.rooms && isValidOccupancy(...params.rooms)) {
      return buildParamsKey(params.checkIn, params.checkOut, params.rooms)
    }
    return ''
  }, [params.checkIn, params.checkOut, params.rooms])

  useEffect(() => {
    if (offerAvailableRatesKey && offer) {
      dispatch(fetchAvailableRatesForOffer(offer, {
        checkIn: params.checkIn!,
        checkOut: params.checkOut!,
        occupants: params.rooms!,
        flightOrigin: params.flightOrigin,
      }))
    }
  // params object may change on every call, it's not stable. So relying on the string key instead
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerAvailableRatesKey, offer])

  return offerAvailableRates[offerAvailableRatesKey] ?? EmptyAvailableRates
}

export function useOffersAvailableRates(
  offersIds: Array<string>,
  params?: {
    checkIn?: string;
    checkOut?: string;
    rooms?: Array<App.Occupants>;
  },
): { [offerId: string]: App.OfferAvailableRates | undefined } {
  const rates = useAppSelector(state => state.offer.offerAvailableRates)
  const offerAvailableRatesKey = params?.checkIn && params.checkOut && params.rooms ? buildParamsKey(params.checkIn, params.checkOut, params.rooms) : undefined

  const offerRates = useMemo(() => {
    return arrayToObject(offersIds,
      (id) => id,
      id => offerAvailableRatesKey ? rates[id]?.[offerAvailableRatesKey] : undefined,
    )
  }, [offerAvailableRatesKey, offersIds, rates])

  return offerRates ?? EmptyObject
}

export default useOfferAvailableRates
