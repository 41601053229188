import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'clsx'
import useOfferList from 'hooks/Offers/useOfferList'
import OfferListLoadMoreButton from './OfferListLoadMoreButton'
import styled from 'styled-components'
import { rem } from 'polished'
import { EmptyArray, fillArray, sortBy, take, unique } from 'lib/array/arrayUtils'
import OfferListOfferTile from './OfferListTiles/OfferListOfferTile'
import noop from 'lib/function/noop'
import useMountedEffect from 'hooks/useMountedEffect'
import Typography from 'components/Luxkit/Typography/Typography'
import OfferListLoadingOfferTile from './OfferListTiles/OfferListLoadingOfferTile'
import OfferListEventsContext, { OfferListEvents } from './OfferListEventsContext'

import { getSearchTypeFromFilters } from 'lib/search/searchUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import arraysEqual from 'lib/array/arraysEqual'

const OfferGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  width: 100%;

  &.fullWidth {
    > * {
      &:first-child {
        padding: 0 ${rem(20)};
      }
    }
  }
`

function tryGetOfferListLoaderType(filters: App.OfferListFilters): App.OfferListOfferType | undefined {
  const { offerTypes } = filters

  if (!offerTypes) {
    return
  }

  if (arraysEqual(offerTypes, [OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2])) {
    return OFFER_TYPE_TOUR_V2
  }

  if (offerTypes.length === 1) {
    return offerTypes[0]
  }
}

export interface OfferListV2Item {
  id: string;
  position: number | 'start' | 'end';
  show?: boolean | 'hasOffers' | 'hasNoOffers' | 'always';
  columnWidth?: number;
  element?: React.ReactNode;
}

interface Props {
  className?: string;
  filters?: App.OfferListFilters;
  interstitials?: Array<OfferListV2Item>;
  random?: boolean;
  /**
   * @default 4
   */
  pageSize?: number;
  eagerLoadFirstImage?: boolean;
  /**
   * @default frontpage
   */
  tileStyle?: 'frontpage' | 'search' | 'hero';
  /**
   * @default 1
   */
  defaultPage?: number;
  onPageChange?: (nextPage: number) => void;
  /**
   * @default infinite
   */
  maxOffers?: number | 'infinite';
  /**
   * Adds the ability to provide your own offer list
   **/
  extraOfferListAndAction?: OfferListAndAction;
  /**
   * Whether or not to filter out the sold out offers returned in the list
   * Note: not all end points return sold out, but for those that do, this will filter them
   * @defaults false
   */
  filterSoldOut?: boolean;
  /**
   * Offer ids to exclude from the offer list
   **/
  excludedIds?: Array<string>;

  /**
   * Whether or not the offer list is rendered in full width
   */
  fullWidth?: boolean;

  /**
   * Whether or not the client will check the availability of the offer
   */
  clientCheckAvailability?: boolean;
}

export enum OfferListAction {
  OVERRIDE = 'override',
  PRIORITIZE = 'prioritize', // prioritize the new list offers
}

export interface OfferListAndAction {
  action: OfferListAction
  offerList: App.OfferList
}

/**
 * Features to add support for:
 * - excluded offer ids
 * - minimum offers before displaying
 */
function OfferList(props: Props) {
  const {
    className,
    filters = EmptyObject,
    interstitials = EmptyArray,
    random,
    pageSize = 4,
    eagerLoadFirstImage,
    tileStyle = 'frontpage',
    defaultPage = 1,
    onPageChange = noop,
    maxOffers = 'infinite',
    extraOfferListAndAction,
    filterSoldOut,
    excludedIds,
    clientCheckAvailability,
  } = props

  const [page, setPage] = useState<number>(defaultPage)
  const fireOfferListEvent = useContext(OfferListEventsContext)

  const incrementPage = useCallback(() => {
    setPage((prevState) => prevState + 1)
  }, [])

  useMountedEffect(() => {
    onPageChange(page)
  }, [page])

  const searchType = getSearchTypeFromFilters(filters)
  const localOfferlist = useOfferList(filters, {
    randomize: random,
    disabled: extraOfferListAndAction?.action === OfferListAction.OVERRIDE,
    // TODO: this component shouldn't know about search type, move it to the implementation instead
    filterSoldOut: filterSoldOut || searchType === 'destination',
    excludedIds,
    clientCheckAvailability,
  })

  // construct a new offerList based on the the new offerList action
  const offerList = useMemo(() => {
    let updatedOfferList: App.OfferList = localOfferlist

    if (extraOfferListAndAction?.action === OfferListAction.OVERRIDE) {
      updatedOfferList = extraOfferListAndAction.offerList
    } else if (extraOfferListAndAction?.action === OfferListAction.PRIORITIZE) {
      const localOfferlistOfferIds = localOfferlist.offerIds
      const extraOfferlistOfferIds = extraOfferListAndAction.offerList.offerIds
      // sort the localOfferlist offerIds based on the order of newOfferlist offerIds
      const localOfferlistOfferIdsSet = new Set(localOfferlistOfferIds)
      const commonIds = extraOfferlistOfferIds.filter(item => localOfferlistOfferIdsSet.has(item))
      const finalOfferIds = unique([...commonIds, ...localOfferlistOfferIds])

      updatedOfferList = {
        fetching: extraOfferListAndAction.offerList.fetching || localOfferlist.fetching,
        error: extraOfferListAndAction.offerList.error || localOfferlist.error,
        offerIds: finalOfferIds,
        key: localOfferlist.key,
        offerCount: finalOfferIds.length,
      }
    }

    return updatedOfferList
  }, [extraOfferListAndAction, localOfferlist])

  // quick dirty optimisation to not pass filters to front page and break memoisation
  // only search style tiles *require* the filters object
  const searchFilters = tileStyle === 'search' ? filters : undefined
  const shownElementCount = page * pageSize

  const totalOfferCount = Math.min(
    (maxOffers === 'infinite' ? offerList.offerIds.length : maxOffers),
    offerList.offerIds.length,
  )

  useEffect(() => {
    if (!offerList.fetching) {
      fireOfferListEvent(
        OfferListEvents.listLoad,
        { key: offerList.key, list: offerList },
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerList])

  let offerItems: Array<OfferListV2Item>

  if (offerList.fetching) {
    // list is fetching, return 4 placeholder tiles of the current style
    offerItems = fillArray(4).map<OfferListV2Item>((index) => ({
      id: `${index}-loader`,
      position: 'start',
      element: <OfferListLoadingOfferTile
        tileStyle={tileStyle}
        offerType={tryGetOfferListLoaderType(filters)}
      />,
    }))
  } else {
    const offerIds = take(offerList.offerIds, maxOffers === 'infinite' ? shownElementCount : maxOffers)
    // maximum number of offers we'll show is the max count, so only process them
    offerItems = offerIds.map<OfferListV2Item>((id, index) => ({
      id,
      position: index,
      element: <OfferListOfferTile
        offerId={id}
        position={index}
        eagerLoadImage={index === 0 && eagerLoadFirstImage}
        filters={searchFilters}
        tileStyle={tileStyle}
      />,
    }))
  }

  // Extras are added first as if they have a position set, we want to that to go first
  const filteredExtras = interstitials.filter(item => {
    // we don't know what the 'end' or a position is until we actually have the list
    if (item.position !== 'start' && offerList.fetching) return false

    if (item.show === 'always') return true
    if (item.show === 'hasOffers') return !offerList.fetching && offerItems.length > 0
    if (item.show === 'hasNoOffers') return !offerList.fetching && offerItems.length === 0
    // wondering why your item is showing when it shouldn't? Always cast to a boolean
    if (typeof item.show === 'boolean') return item.show

    return true
  })

  const combinedItems = filteredExtras.concat(offerItems)
  const visibleItems = sortBy(combinedItems, item => {
    if (item.position === 'end') {
      return 10000
    } else if (item.position === 'start') {
      return -1
    } else {
      return item.position
    }
  }, 'asc')

  const totalItemCount = totalOfferCount + filteredExtras.length
  const hasMore = totalItemCount > shownElementCount

  return (
    <OfferGrid
      className={classNames(className, { fullWidth: props.fullWidth })}
      data-testid="offer-list"
    >
      {take(visibleItems, shownElementCount).map(item => <React.Fragment key={item.id}>
        {item.element}
      </React.Fragment>,
      )}
      {hasMore && <Typography align="center">
        <OfferListLoadMoreButton onClick={incrementPage} size="large" onScrollNear={incrementPage}>
          Load more
        </OfferListLoadMoreButton>
      </Typography>
      }
    </OfferGrid>
  )
}

export default OfferList
