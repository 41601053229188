import React, { useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import noop from 'lib/function/noop'
import TextButton from 'components/Luxkit/Button/TextButton'

const SCROLL_NEAR_THRESHOLD = 500

interface Props extends Omit<React.ComponentProps<typeof TextButton>, 'kind'> {
  onScrollNear?: () => void;
}

function OfferListLoadMoreButton(props: Props) {
  const { children, onScrollNear = noop, ...buttonProps } = props

  const [buttonRef, isInView] = useInView({
    rootMargin: `${SCROLL_NEAR_THRESHOLD}px`,
  })

  useEffect(() => {
    if (isInView) {
      onScrollNear()
    }
    // eslint-disable-next-line
  }, [isInView])

  return <TextButton kind="primary" {...buttonProps} ref={buttonRef}>
    {children}
  </TextButton>
}

OfferListLoadMoreButton.defaultProps = {
  onScrollNear: noop,
}

export default OfferListLoadMoreButton
